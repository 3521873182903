/** @jsx jsx */
import { jsx, Grid, Flex } from 'theme-ui'
import { Buffer } from 'buffer'
import Layout from '../../components/layout'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { responsive } from '../../utils/helpers'
import { ImageCard } from '../../components/card'
import Hero, { HeroTitle } from '../../components/hero'
import { graphql } from 'gatsby'
import ShopIcon from '../../images/shop.png'
import MetaData from '../../components/metadata'

const Category = props => {
  const contentSX = {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }

  const iconSX = {
    width: '277px',
    height: 'auto',
    position: 'relative',
    left: responsive('0px', '-30px'),
  }

  const page = props.data?.contentfulProductCollection

  const variants = props.data?.allShopifyProductVariant

  const products = page?.products
    .map((x, i) => {
      const variant = variants?.edges?.find(
        v => v.node.shopifyId === Buffer.from(x, 'base64').toString('utf8')
      )
      return variant?.node?.product
    })
    .reduce((total, x) => {
      if (typeof x !== 'undefined') total.push(x)
      return total
    }, [])

  return (
    <Layout {...props}>
      <MetaData title={page?.title} />
      <Hero
        back="/shop"
        data={[
          {
            type: 'Custom',
            backgroundImage: page?.hero,
            children: (
              <Grid
                columns={responsive(1, '1fr 320px')}
                gap={'30%'}
                sx={{ width: '100%' }}
              >
                <Flex sx={contentSX}>
                  <img src={ShopIcon} alt="FGR Shop" sx={iconSX} />
                  <HeroTitle variant="large">{page?.title}</HeroTitle>
                </Flex>
              </Grid>
            ),
          },
        ]}
      />
      {/* <HeroLayout variant="center" background={page?.hero?.file?.url}>
        
      </HeroLayout> */}

      <Wrapper>
        <Inner>
          <Grid columns={responsive(1, 4)} mt={4} mb={8}>
            {products.map((x, i) => {
              const thumb =
                x.media.length > 0 ? x.media[0].image : { originalSrc: '' }
              return (
                <ImageCard
                  key={i}
                  featured={false}
                  thumbnail={{ url: thumb?.originalSrc }}
                  title={x.title}
                  button={{
                    url: `/shop/${x.handle}`,
                    label: 'Shop',
                  }}
                  height={421}
                  shop
                />
              )
            })}
          </Grid>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ShopCollection($slug: String!) {
    contentfulProductCollection(slug: { eq: $slug }) {
      title
      products
      hero {
        file {
          url
        }
        gatsbyImageData(layout: FIXED, width: 1700, height: 580)
      }
    }

    allShopifyProductVariant(filter: { product: { id: { ne: null } } }) {
      edges {
        node {
          shopifyId
          product {
            handle
            title
            media {
              ... on ShopifyMediaImage {
                image {
                  originalSrc
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Category
